export default {
  methods: {
    async contactAlert() {
      alert(
        "No fue posible hacer cuenta, comuniquese con el whastapp +573124532242 "
      )
    },
    async boxAlert() {
      alert(
        "No fue posible registrar MidBox, el ID debe estar en minusculas, comuniquese con el whastapp +573124532242 "
      )
    },
    getFormatedOffsetDate(dateNoOffset) {
      // get browser offset
      const offset = new Date().getTimezoneOffset()
      // get hours and minutes
      const offsetHours = Math.floor(offset / 60)
      const offsetMinutes = offset - offsetHours * 60

      return (
        dateNoOffset +
        `${offset > 0 ? "-" : "+"}${
          offsetHours < 10 ? "0" + offsetHours : offsetHours
        }:${offsetMinutes < 10 ? "0" + offsetMinutes : offsetMinutes}`
      )
    }
  }
}
