import { Toast } from "@capacitor/toast"
export default {
  methods: {
    async showWellcomeToast() {
      await Toast.show({
        text: "Bienvenido/a!, si cuentas con una midbox, registrala. Si quieres entrar en el modo demo, agrega una midbox con ID 'demo'"
      })
    }
  }
}
