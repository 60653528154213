<template>
  <div
    class="
      col-10 col-md-6 col-xl-4
      bg-white
      rounded
      p-4 p-md-5
      shadow
      d-flex
      flex-column
      align-items-center
    "
  >
    <label
      :class="{
        'mb-3 w-100': true,
        error: $v.name.$error,
        green: $v.name.$dirty && !$v.name.$error
      }"
    >
      <!-- <span class="d-block mb-2">Correo electrónico</span> -->
      <input
        class="w-100 brown-wGreyMedium font-rem-0-8 rounded border"
        v-model="name"
        @change="$v.name.$touch()"
        placeholder="Nombre"
      />
    </label>
    <transition-expanded>
      <div class="align-self-start" v-if="$v.name.$dirty && $v.name.$error">
        <p class="font-rem-0-75 error">
          Debe ser un nombre de almenos 4 caracteres
        </p>
      </div>
    </transition-expanded>

    <label
      :class="{
        'mb-3 w-100': true,
        error: $v.phone.$error,
        green: $v.phone.$dirty && !$v.phone.$error
      }"
    >
      <!-- <span class="d-block mb-2">Correo electrónico</span> -->
      <input
        class="w-100 brown-wGreyMedium font-rem-0-8 rounded border"
        v-model="phone"
        type="number"
        @change="$v.phone.$touch()"
        :disabled="
          $route.query.new || (phone && phone.length) > 6 ? false : true
        "
        placeholder="Celular"
      />
    </label>
    <transition-expanded>
      <div class="align-self-start" v-if="$v.phone.$dirty && $v.phone.$error">
        <p class="font-rem-0-75 error">
          Debe ser un numero valido Colombiano, sin codigo de pais
        </p>
      </div>
    </transition-expanded>

    <label
      :class="{
        'mb-3 w-100': true,
        error: $v.email.$error,
        green: $v.email.$dirty && !$v.email.$error
      }"
    >
      <!-- <span class="d-block mb-2">Correo electrónico</span> -->
      <input
        class="w-100 brown-wGreyMedium font-rem-0-8 rounded border"
        v-model="email"
        @change="$v.email.$touch()"
        :disabled="
          $route.query.new || (email && email.length) > 6 ? false : true
        "
        placeholder="Correo electrónico"
      /> </label
    ><transition-expanded>
      <div class="align-self-start" v-if="$v.email.$dirty && $v.email.$error">
        <p class="font-rem-0-75 error">Debe ser un email valido</p>
      </div>
    </transition-expanded>

    <label
      :class="{
        'mb-3 w-100': true,
        error: $v.password.$error,
        green: $v.password.$dirty && !$v.password.$error
      }"
    >
      <!-- <span class="d-block mb-2">Correo electrónico</span> -->
      <input
        class="w-100 brown-wGreyMedium font-rem-0-8 rounded border"
        v-model="password"
        @change="$v.password.$touch()"
        type="password"
        placeholder="Contraseña"
      />
    </label>
    <transition-expanded>
      <div
        class="align-self-start"
        v-if="$v.password.$dirty && $v.password.$error"
      >
        <p class="font-rem-0-75 error">debe tener almenos 6 caracteres</p>
      </div>
    </transition-expanded>

    <label class="mb-3 w-100">
      <!-- <span class="d-block mb-2">Correo electrónico</span> -->
      <input
        class="w-100 brown-wGreyMedium font-rem-0-8 rounded border"
        v-model="company"
        @change="$v.$touch()"
        placeholder="Institución / empresa"
      />
    </label>
    <button
      class="
        py-2
        px-3
        mt-3
        w-75
        h-50
        rounded
        bg-dark-green
        shadow-sm
        pointer
        d-flex
        justify-content-center
        text-white
        w-fit-content
      "
      :disabled="$v.$invalid"
      @click="saveClick()"
    >
      <span v-if="!user.id" class="align-self-center"> registrarse</span>
      <span v-else class="align-self-center"> Guardar cambios</span>
    </button>
  </div>
</template>

<script>
import {
  required,
  minLength,
  integer,
  between,
  email
} from "vuelidate/lib/validators"
import toast from "@/mixins/toast"
import utils from "@/mixins/utils"
import { mapGetters, mapActions, mapMutations } from "vuex"
export default {
  components: {
    "transition-expanded": () => import("@/components/TransitionExpanded")
  },
  mixins: [toast, utils],
  computed: {
    ...mapGetters(["user"]),
    name: {
      get() {
        return this.user.name
      },
      set(value) {
        this.UPDATE_USER({ value, key: "name" })
      }
    },
    phone: {
      get() {
        return this.user.phone
      },
      set(value) {
        this.UPDATE_USER({ value, key: "phone" })
      }
    },
    email: {
      get() {
        return this.user.email
      },
      set(value) {
        this.UPDATE_USER({ value, key: "email" })
      }
    },
    password: {
      get() {
        return this.user.password
      },
      set(value) {
        this.UPDATE_USER({ value, key: "password" })
      }
    },
    company: {
      get() {
        return this.user.company
      },
      set(value) {
        this.UPDATE_USER({ value, key: "company" })
      }
    }
  },
  methods: {
    ...mapActions(["updateUser", "SIGN_UP"]),
    ...mapMutations(["UPDATE_USER"]),
    saveClick() {
      if (!this.user.id) {
        this.signUp()
      } else {
        this.navUpdate()
      }
    },
    async signUp() {
      console.log("ready cto signUp", this.user)
      const rta = await this.SIGN_UP()
      if (rta) {
        console.log(rta)
        await this.showWellcomeToast()
        this.$router.push({
          name: "View",
          params: { view: "boxes" },
          query: { newUser: "true" }
        })
      } else {
        // no paso nada, alert
        this.contactAlert()
      }
    },
    async navUpdate() {
      const rta = await this.updateUser()
      //console.log("RTA", rta)
      if (rta) {
        this.$router.push({ query: { mode: "view" } })
      } else {
        console.log("Erorrr, notification")
      }
    }
  },
  validations: {
    name: {
      required,
      minLength: minLength(4)
    },
    phone: {
      //required,
      between: between(999999999, 9999999999),
      integer
    },
    email: {
      required,
      email
    },
    password: {
      required,
      minLength: minLength(6)
    }
  }
}
</script>

<style lang="scss" scoped>
.error {
  color: $wAlert !important;
  label {
    color: $wAlert;
  }
  input,
  textarea {
    border: 1px $wAlert solid !important;
  }
  .field-input {
    border: 1px $wAlert solid !important;
  }
  .multiselect__tags {
    border: 1px $wAlert solid !important;
  }
}
.green {
  input {
    border: 1px $wGreenSucc solid !important;
  }
  .field-input {
    border: 1px $wGreenSucc solid !important;
  }
  .multiselect__tags {
    border: 1px $wGreenSucc solid !important;
  }
}
</style>
